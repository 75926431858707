<template>
  <div class="row align-items-stretch">
    <div
      class="col-6 mb-3 position-relative"
      v-for="type in types"
      v-bind:key="type.title"
    >
      <!-- Soon -->
      <div
        v-if="type.soon"
        class="font-12 position-absolute alert-warning px-2 py-1 rounded"
        style="z-index: 1; right: 20px; top: 5px"
        v-tooltip.bottom="'Yakında'"
      >
        <i class="fas fa-clock"></i>
      </div>
      <!-- ./Soon -->

      <!-- Disabled -->
      <div
        v-if="isDisabled(type.type)"
        class="font-12 position-absolute alert-info px-2 py-1 rounded"
        style="z-index: 1; right: 20px; top: 5px"
        v-tooltip.bottom="'Kullanım Limitine Ulaşıldı'"
      >
        <i class="fas fa-info-circle"></i>
      </div>
      <!-- ./Disabled -->

      <!-- Selection -->
      <div
        v-on:click="onSelect(type)"
        v-bind:class="[
          'border rounded p-3 text-center cursor-pointer type-wrapper',
          type.soon || isDisabled(type.type) ? 'disabled' : '',
        ]"
      >
        <i v-bind:class="`${type.icon} fa-lg`"></i>
        <div class="d-block">
          <span class="font-weight-bold">{{ type.title }}</span>
        </div>
      </div>
      <!-- ./Selection -->
    </div>
  </div>
</template>
<script>
export default {
  name: "ContentType",
  props: {
    selectedTypes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      types: [
        {
          title: "İçerik",
          type: "editor",
          icon: "fas fa-bars",
          soon: false,
          value: null,
        },
        {
          title: "Ürün",
          type: "product",
          icon: "fas fa-cubes",
          soon: true,
          value: null,
        },
        {
          title: "Paket",
          type: "package",
          icon: "fas fa-box",
          soon: true,
          value: null,
        },
        {
          title: "Kampanya",
          type: "campaign",
          icon: "fas fa-calendar-alt",
          soon: true,
          value: null,
        },
      ],
    };
  },
  methods: {
    isDisabled(type) {
      return this.selectedTypes.filter((s) => s == type).length;
    },
    onSelect(type) {
      if (!type.disabled) {
        this.$emit("select-type", type);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.type-wrapper {
  transition: all 200ms ease-in-out;
  &.disabled {
    cursor: default;
    color: rgba($color: #000000, $alpha: 0.2);
    border-color: rgba($color: #000000, $alpha: 0.2);
  }
  &:not(.disabled):hover {
    border-color: var(--primary);
  }
}
</style>